import { Grid, Paper } from "@mantine/core";
import React, { useState } from "react";
import Phones from "../components/integrations/phones";
import Whatsapp from "../components/integrations/whatsapp";
import Zapier from "../components/integrations/zapier";
import ApiKeys from "../components/integrations/api_keys";

export default function Integrations(){
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Phones />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Whatsapp />
                </Paper>
            </Grid.Col>
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <PaymentMethod />
                </Paper>
            </Grid.Col> */}
            <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <ApiKeys />
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <Zapier />
                </Paper>
            </Grid.Col>
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <Stripe />
                </Paper>
            </Grid.Col> */}
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <PayPay />
                </Paper>
            </Grid.Col> */}
            {/* <Grid.Col span={{ base: 12, md: 6 }}>
                <Paper p="md" shadow="md">
                    <GoogleAnalytics />
                </Paper>
            </Grid.Col> */}
            {/* <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Sendgrid />
                </Paper>
            </Grid.Col> */}
            {/* <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Webhooks />
                </Paper>
            </Grid.Col> */}
            {/* <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" shadow="md">
                    <Scripts />
                </Paper>
            </Grid.Col> */}
        </Grid>
    </div>
}
